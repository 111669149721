import React, { useEffect, useState } from "react";
import styles from "./securityFooter.module.scss";
import { formatPercentage } from "../../../lib/utilities";
import { getTrendIcon } from "../../../lib/utilitiesComponent";

const SecurityFooter = ({ footer, years, security }) => {
  const [yearsLabel, setYearsLabel] = useState("all");

  useEffect(() => {
    if (!years) {
      setYearsLabel("all");
    } else if (years === 1) {
      setYearsLabel("1y");
    } else if (years === 3) {
      setYearsLabel("3y");
    }
  }, [years]);
  return (
    <>
      {security ? (
        <div className={styles.grid}>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>CAGR</p>
            <p>
              <b className={styles.number}>
                {formatPercentage(security["cagr"])}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Max Drawdown</p>
            <p>
              <b className={styles.number}>
                {formatPercentage(security["max_drawdown"])}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Driver</p>
            <p>
              <b className={`${styles.number} ${styles.smaller}`}>
                {security["driver"]}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Status</p>
            <p>
              <b className={`${styles.number} ${styles.smaller}`}>
                {security["active"] ? "ACTIVE" : "INACTIVE"}
              </b>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Score %</p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>
                {formatPercentage(security["score"])}
              </b>
              <span className="trend">
                {getTrendIcon(security["score_trend"])}
              </span>
            </p>
          </div>

          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>MA 26 Weeks</p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_ma1"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_ma1_trend"])}
              </span>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>MA 104 Weeks</p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_ma2"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_ma2_trend"])}
              </span>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>Slope 52 Weeks</p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_slope"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_slope_trend"])}
              </span>
            </p>
          </div>
          <div className={`${styles.grid_card} ${styles.footer_card}`}>
            <p className={styles.label}>R^2 52 Weeks</p>
            <p className={styles.flex_icon}>
              <b className={styles.number}>{security["backtesting_r2"]}</b>
              <span className="trend">
                {getTrendIcon(security["backtesting_r2_trend"])}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.footer_card}>
            <p className={styles.label}>CAGR</p>
            <p>
              <b className={styles.number}>
                {formatPercentage(footer["cagr"][yearsLabel])}
              </b>
            </p>
          </div>
          <div className={styles.footer_card}>
            <p className={styles.label}>Max Drawdown</p>
            <p>
              <b className={styles.number}>
                {formatPercentage(footer["max_drawdown"][yearsLabel])}
              </b>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SecurityFooter;
