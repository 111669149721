import { useState } from "react";
import { login } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import Hgroup from "../components/Shared/Hgoup/Hgroup";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState({});

  const loginAttempt = async () => {
    login(email, password).then((e) => {
      if (e === 200) {
        // navigate("/");
        window.location.reload();
      } else {
        setLoginError(e.error);
      }
    });
  };

  return (
    <div className="black-page">
      <Menu pageStyle="black" />
      <div className="login-container">
        <div className="page-container md:px-11 px-5">
          <Hgroup title="Login" subtitle="" />
        </div>
        <div className="md:grid md:grid-cols-12 gap-8 mb-8 overflow-auto">
          <div className="col-span-12 md:col-span-9 lg:col-span-6 2xl:col-span-4 md:px-11 px-5">
            <div className="mb-10">
              <label className="block mb-1 text-xs uppercase">E-mail</label>
              <input
                className="w-full bg-indigo-50 border-gray-300"
                type="email"
                name="fldEmail"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-10">
              <label className="block mb-1 text-xs uppercase">Password</label>
              <input
                className="w-full  bg-indigo-50 border-gray-300"
                type="password"
                name="fldPassword"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <span>{loginError.message}</span>
            </div>
            <div>
              <button
                className="w-40 h-10 bg-blue-900 text-white rounded-lg"
                onClick={loginAttempt}
                type="submit"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
