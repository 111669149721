import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { getTrendIcon } from "../../lib/utilitiesComponent";
import { formatPercentage } from "../../lib/utilities";

const COLORS = ["#B5FF00", "#F0F0F0"];

export default function PieChartSecurity({ data }) {
  const [graph, setGraph] = useState([]);

  useEffect(() => {
    const last = data["active_securities"].pop();
    const lastObj = [
      {
        name: "active_securities",
        value: last["active_securities"],
      },
      {
        name: "total_securities",
        value: last["total_securities"],
      },
    ];
    setGraph(lastObj);
  }, [data]);

  return (
    <div className="pieContainer">
      <PieChart width={150} height={150}>
        <Pie
          data={graph}
          innerRadius={50}
          outerRadius={70}
          startAngle={90}
          endAngle={-270}
          fill="#000000"
          paddingAngle={0}
          dataKey="value"
        >
          {graph.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      {graph[0] && graph[1] ? (
        <div className="label">
          <span>
            {graph[0]["value"]}/{graph[1]["value"]}
          </span>
          <p>{formatPercentage(data["active_securities_percentage"])}</p>
        </div>
      ) : (
        ""
      )}
      <div className="today">
        {data["active_securities_trend"] === "-" ? (
          <span>Reducing</span>
        ) : data["active_securities_trend"] === "+" ? (
          <span>Increasing</span>
        ) : (
          <span>Remains constant</span>
        )}

        <span>{getTrendIcon(data["active_securities_trend"])}</span>
      </div>
    </div>
  );
}
