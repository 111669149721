import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import LinearChart from "../components/Chart/LinearChart";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import Header from "../components/Shared/Header/Header";
import SectorsList from "../components/SectorsList/SectorsList";
import ListTable from "../components/HeaderBlocks/ListTable";
import BarGraph from "../components/HeaderBlocks/BarGraph";
import LineGraph from "../components/HeaderBlocks/LineGraph";
import BigHeader from "../components/Shared/Header/BigHeader";

const Strategy = () => {
  const { strategyCode } = useParams();
  const [strategy, setStrategy] = useState({});
  const [sectors, setSectors] = useState([]);
  const [years, setYears] = useState(null);

  useEffect(() => {
    const strategy = JSON.parse(
      sessionStorage.getItem(strategyCode + "_strategy")
    );
    const sectors = JSON.parse(
      sessionStorage.getItem(strategyCode + "_sector_list")
    );
    if (strategy) {
      setStrategy(strategy);
    }
    if (sectors) {
      setSectors(sectors);
    }
    getApi("strategy_details", { strategy: strategyCode }).then((r) => {
      setStrategy(r["info"]);
      sessionStorage.setItem(
        strategyCode + "_strategy",
        JSON.stringify(r["info"])
      );
    });
    getApi("sector_list", { strategy: strategyCode }).then((r) => {
      setSectors(r);
      sessionStorage.setItem(strategyCode + "_sector_list", JSON.stringify(r));
    });
  }, [strategyCode]);

  return (
    <>
      <BigHeader strategyCode={strategyCode} strategy={strategy} />

      {strategy["color"] ? (
        <>
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to={"/"}>Index</Link>
              </li>
              <li>{strategyCode + " " + strategy["name"]}</li>
            </ul>
          </section>

          <section className="cumulated-return">
            <div className="graph-container">
              <h2>Cumulated Return</h2>
              <LinearChart
                data={strategy["cumulated_returns"]}
                xAxisKey="end_date"
                pointKey="value"
                colorLine="#163470"
                legend={true}
                tooltip={["end_date", "drawdown_risk", "value"]}
                hasFilter={true}
                setYears={setYears}
              />
            </div>

            <aside className="sidebar">
              <SecurityFooter
                footer={{
                  cagr: {
                    all: strategy["cagr"],
                    "1y": strategy["cagr_1y"],
                    "3y": strategy["cagr_3y"],
                  },
                  max_drawdown: {
                    all: strategy["max_drawdown"],
                    "1y": strategy["max_drawdown_1y"],
                    "3y": strategy["max_drawdown_3y"],
                  },
                }}
                years={years}
              />
            </aside>
          </section>
        </>
      ) : null}

      {sectors.length > 0 ? (
        <SectorsList strategyCode={strategyCode} sectors={sectors} />
      ) : (
        <div className="animate-pulse bodyPlaceholder">
          <h2>
            <span className="loader">Loading</span>
            <p> {strategyCode}</p>
          </h2>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Strategy;
