import Menu from "../Menu/Menu";
import Header from "./Header";
import ListTable from "../../HeaderBlocks/ListTable";
import BarGraph from "../../HeaderBlocks/BarGraph";
import LineGraph from "../../HeaderBlocks/LineGraph";

const BigHeader = ({ strategy, strategyCode }) => {
  return (
    <>
      {strategy["color"] ? (
        <>
          <Menu background={strategy["color"]} />
          <Header data={strategy} strategyCode={strategyCode} />
          <section className="block-row">
            <ListTable data={strategy} />
            <div className="second-row">
              <BarGraph data={strategy} />
              <LineGraph data={strategy} />
            </div>
          </section>
        </>
      ) : (
        <div className="animate-pulse">
          <Menu background={"#333333"} />
          <Header
            data={{ color: "#333333", name: "...", description: "..." }}
            strategyCode="..."
          />
        </div>
      )}
    </>
  );
};

export default BigHeader;
