import styles from "./hpcard.module.scss";
import { Link } from "react-router-dom";

const HPCard = ({ title, description, color, link }) => {
  return (
    <Link
      to={`${link}`}
      className={`xl:col-span-5 lg:col-span-6 grid col-span-12 xl-:mb-32 mb-0 pb-8 ${styles.card}`}
      style={{ borderColor: `${color}` }}
    >
      <div className={styles.innerCard} style={{ borderColor: `${color}` }}>
        <h2 className={styles.cardTitle}>{title}</h2>
        <p className={styles.cardDescription}>{description}</p>
      </div>
    </Link>
  );
};

export default HPCard;
