import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";

export default function ActiveStockLineChart({ data }) {
  return (
    <LineChart width={350} height={215} data={data}>
      <CartesianGrid strokeDasharray="1 1" />
      <XAxis dataKey="end_date" padding={{ left: 20, right: 20 }} />
      <YAxis />
      <Line
        type="monotone"
        dataKey="active_securities"
        stroke="#ffffff"
        dot={{ fill: "#B2C0C4" }}
        activeDot={{ r: 1 }}
      />
      <Line
        type="linear"
        dataKey="active_securities_moving_avg"
        stroke="#B2C0C4"
        dot={false}
      />
    </LineChart>
  );
}
