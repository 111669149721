const Header = ({ strategyCode, data }) => {
  return (
    <section
      className="sub-header"
      style={{ backgroundColor: `${data["color"]}` }}
    >
      <div className="page-container md:px-11 px-5">
        <h1>{strategyCode + " " + data["name"]}</h1>
        <p>{data["description"]}</p>
      </div>
    </section>
  );
};

export default Header;
