import ActiveStockLineChart from "../Chart/ActiveStockLineChart";
import PieChartSecurity from "../Chart/PieChart";

const BarGraph = ({ data }) => {
  return (
    <div className="block-module line-graph">
      <h2>Active Stocks</h2>
      <div className="graph-container">
        <ActiveStockLineChart data={data["active_securities"]} />
        <PieChartSecurity data={data} />
      </div>
    </div>
  );
};

export default BarGraph;
