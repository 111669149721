import { drawdownRiskTable } from "../../lib/utilities";
import styles from "../Chart/chart.module.scss";

const LineGraph = ({ data }) => {
  const drawdown =
    data["cumulated_returns"][data["cumulated_returns"].length - 1][
      "drawdown_risk"
    ];

  return (
    <div className="block-module bar-graph">
      <h2>Drawdown Risk</h2>
      {/* todo: manca dato il api */}
      <p
        className="signal"
        style={{ color: drawdownRiskTable(drawdown, "color") }}
      >
        {drawdownRiskTable(drawdown, "label")}
      </p>
      <ul className="bar">
        <li className={`${drawdown > 2 ? "good" : "alert"}`}>01</li>
        <li
          className={`${
            drawdown > 2 ? "good" : drawdown > 1 ? "alert" : "empty"
          }`}
        >
          02
        </li>
        <li className={`${drawdown > 2 ? "good" : "empty"}`}>03</li>
        <li className={`${drawdown > 3 ? "good" : "empty"}`}>04</li>
        <li className={`${drawdown > 4 ? "good" : "empty"}`}>05</li>
      </ul>
      <ul className="legend">
        <li>
          <span>01</span> extreme risk - inactive
        </li>
        <li>
          <span>02</span> very high risk
        </li>
        <li>
          <span>03</span> high risk
        </li>
        <li>
          <span>04</span> low risk
        </li>
        <li>
          <span>05</span> very low risk
        </li>
      </ul>
    </div>
  );
};

export default LineGraph;
