import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { useEffect, useState } from "react";
import { drawdownRiskTable, formatPercentage } from "../../lib/utilities";
import styles from "./chart.module.scss";
import { scaleSymlog, scaleLog } from "d3";

const CustomTooltip = ({ active, payload, label, data }) => {
  const [dataPayload, setDataPayload] = useState({});
  useEffect(() => {
    if (payload.length > 0) {
      setDataPayload(payload[0]["payload"]);
    }
  }, [payload]);

  if (active) {
    return (
      <div
        className={`
          ${styles.tooltip}`}
      >
        {dataPayload["end_date"] ? (
          <>
            <div>{dataPayload["end_date"]}</div>
            <div>
              {drawdownRiskTable(dataPayload["drawdown_risk"], "label")}
            </div>
            <div>{formatPercentage(dataPayload["value"])}</div>
          </>
        ) : (
          ""
        )}
        {dataPayload["signal"] ? (
          <>
            <div>{dataPayload["end"]}</div>
            <div>{dataPayload["signal"]}</div>
            <div>Share Price: ${dataPayload["share_price"]}</div>
            <div>Druid Strategy: ${dataPayload["druid_strategy_adj"]}</div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }

  return null;
};

const renderLegend = (props) => {
  console.log(props);
  const { payload } = props;

  return (
    <ul className={styles.legend}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>
          <span>{cleanLegend(entry.value)}</span>
          <span style={{ backgroundColor: entry.color }}></span>
        </li>
      ))}
    </ul>
  );
};

const cleanLegend = (label) => {
  let newLabel = label.replace("_", " ");
  newLabel = newLabel.replace("_adj", "");
  if (newLabel === "value") {
    return "forward testing";
  }
  return newLabel;
};

const LinearChart = ({
  data,
  xAxisKey,
  pointKey,
  colorLine,
  secondLine,
  colorSecondLine,
  legend,
  tooltip,
  hasFilter,
  setYears,
}) => {
  const [filterActive, setFilterActive] = useState(0);
  const [dataChart, setDataChart] = useState({});
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const scale = scaleSymlog([0, 100], [min, max]).constant(10);

  useEffect(() => {
    if (pointKey === "share_price") {
      data = [...data.reverse()];
    }
    const min1 = Math.min(...data.map((item) => item[pointKey]));
    const max1 = Math.max(...data.map((item) => item[pointKey]));

    const min2 = secondLine
      ? Math.min(...data.map((item) => item[secondLine]))
      : 0;
    const max2 = secondLine
      ? Math.max(...data.map((item) => item[secondLine]))
      : 0;

    setMin(Math.min(min1, min2));
    setMax(Math.max(max1, max2));
    setDataChart(data);
  }, [data]);

  const filterChart = (years) => {
    setYears(years);
    if (years) {
      const startingDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - years)
      );
      let filterDate = data.filter(
        (obj) => Date.parse(obj[xAxisKey]) > startingDate.valueOf()
      );
      const rebase = filterDate[0]["value"];
      filterDate = filterDate.map((e) => {
        return {
          end_date: e["end_date"],
          value: e["value"] - rebase,
          drawdown_risk: e["drawdown_risk"],
        };
      });
      setFilterActive(years);
      setMin(Math.min(...filterDate.map((item) => item[pointKey])));
      setMax(Math.max(...filterDate.map((item) => item[pointKey])));
      setDataChart(filterDate);
    } else {
      setFilterActive(0);
      setMin(Math.min(...data.map((item) => item[pointKey])));
      setMax(Math.max(...data.map((item) => item[pointKey])));
      setDataChart(data);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={600} className="relative">
      {hasFilter ? (
        <div className="xl:absolute -top-5 z-10 mt-2 xl:mt-0 right-0 flex items-center">
          <p className="text-sm pr-2">Show history for: </p>
          <ul className="flex text-sm">
            <li
              className={`${
                filterActive === 1 ? styles.filterActive : ""
              } p-3 px-4 border rounded-l-lg border-r-0 cursor-pointer`}
              onClick={() => filterChart(1)}
            >
              1Y
            </li>
            <li
              className={`${
                filterActive === 3 ? styles.filterActive : ""
              } p-3 border cursor-pointer`}
              onClick={() => filterChart(3)}
            >
              3Y
            </li>
            <li
              className={`${
                filterActive === 0 ? styles.filterActive : ""
              } p-3 border rounded-r-lg border-l-0 cursor-pointer`}
              onClick={() => filterChart()}
            >
              Max
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}

      <LineChart
        data={dataChart}
        margin={{
          top: 50,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          dataKey={xAxisKey}
        />
        <YAxis
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          dataKey={pointKey}
          tickFormatter={(value, i) => {
            if (pointKey === "share_price") {
              return "$" + parseInt(value);
            }
            return formatPercentage(value);
          }}
          // scale={pointKey === "adj_close" ? "auto" : scale}
          // domain={["auto", "auto"]}
          // allowDataOverflow={true}
          // domain={[min > 0 ? 0 : min, max]}
          domain={[min, max]}
        />
        {tooltip && tooltip.length > 0 ? (
          <Tooltip content={<CustomTooltip data={[...tooltip]} />} />
        ) : (
          ""
        )}
        {legend ? (
          <Legend
            align="left"
            verticalAlign="top"
            height={80}
            iconSize={30}
            iconType="plainline"
            content={renderLegend}
          />
        ) : (
          ""
        )}
        <Line
          type="bump"
          dataKey={pointKey}
          strokeWidth={3}
          stroke={colorLine}
          dot={false}
        />
        {secondLine ? (
          <Line
            type="bump"
            dataKey={secondLine}
            strokeWidth={3}
            stroke={colorSecondLine}
            dot={false}
          />
        ) : (
          ""
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LinearChart;
